import React, { useEffect, useState } from 'react';
import axios from 'axios';
import { Container, Grid, Card, CardContent, Typography, CircularProgress, Alert, Box, Button } from '@mui/material';
import { styled } from '@mui/system';

const StyledCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.paper,
  boxShadow: theme.shadows[3],
  borderRadius: theme.shape.borderRadius,
  marginBottom: theme.spacing(3),
  padding: theme.spacing(2),
}));

const StyledTypography = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.primary,
}));

const StyledContainer = styled(Container)(({ theme }) => ({
  paddingTop: theme.spacing(4),
  paddingBottom: theme.spacing(4),
}));

const HeaderCard = styled(Card)(({ theme }) => ({
  backgroundColor: theme.palette.background.default,
  boxShadow: theme.shadows[1],
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(2),
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  marginBottom: theme.spacing(3),
}));

const SectionTitle = styled(Typography)(({ theme }) => ({
  color: theme.palette.text.secondary,
  marginBottom: theme.spacing(1),
  fontWeight: 500,
}));

const OverviewBox = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(1),
  backgroundColor: theme.palette.background.default,
  borderRadius: theme.shape.borderRadius,
}));

const AnalyticsViewer = ({ onLogout }) => {
  const [data, setData] = useState(null);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const token = localStorage.getItem('token');
    if (token) {
      axios.get(`${process.env.REACT_APP_API_URL}/api/analytics`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then(response => {
        setData(response.data);
        setLoading(false);
      })
      .catch(error => {
        if (error.response && error.response.status === 403) {
          localStorage.removeItem('token');
          onLogout();
        } else {
          setError(error.message);
          setLoading(false);
        }
      });
    } else {
      setLoading(false);
      setError('No token found');
    }
  }, [onLogout]);

  if (loading) return <Box display="flex" justifyContent="center" alignItems="center" height="100vh"><CircularProgress /></Box>;
  if (error) return <Alert severity="error">{error}</Alert>;

  const renderData = (title, data) => (
    <StyledCard>
      <CardContent>
        <StyledTypography variant="h5" gutterBottom>{title}</StyledTypography>
        <OverviewBox>
          <SectionTitle variant="h6">Overview</SectionTitle>
          <StyledTypography variant="body1">Page Views: {data.overview.total}</StyledTypography>
          <StyledTypography variant="body1">Visitors: {data.overview.devices}</StyledTypography>
        </OverviewBox>
        <OverviewBox>
          <SectionTitle variant="h6">Referrers</SectionTitle>
          {data.referrers.data.map(ref => (
            <StyledTypography variant="body2" key={ref.key}>{ref.key}: {ref.total}</StyledTypography>
          ))}
        </OverviewBox>
        <OverviewBox>
          <SectionTitle variant="h6">Page Directory</SectionTitle>
          {data.pageViews.data.map(page => (
            <StyledTypography variant="body2" key={page.key}>{page.key}: {page.total}</StyledTypography>
          ))}
        </OverviewBox>
      </CardContent>
    </StyledCard>
  );

  return (
    <StyledContainer>
      <Grid container spacing={3}>
        <Grid item xs={12}>
          <HeaderCard>
            <Typography variant="h4">Analytics Dashboard</Typography>
            <Button variant="contained" color="secondary" onClick={() => {
              localStorage.removeItem('token');
              onLogout();
            }}>Logout</Button>
          </HeaderCard>
        </Grid>
        <Grid item xs={12} md={4}>
          {renderData('Last 24 Hours', data.last24Hours)}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderData('Last 7 Days', data.last7Days)}
        </Grid>
        <Grid item xs={12} md={4}>
          {renderData('Last 30 Days', data.last30Days)}
        </Grid>
      </Grid>
    </StyledContainer>
  );
};

export default AnalyticsViewer;
